<template>
  <div class="container mt-4" style="width: 800px">
    <div class="row ">
    <img src="./iheader.png" alt="header" width="100%" />
  </div>
    <h4 class="text-center"> فاتورة ضريبية</h4>

    <table class="table table-bordered line-height">
      <tr>
        <td class="text-right">
          <span>شركة ابناء عبدالرحمن على البصيلى</span>
        </td>
        <td class="text-right"><span> اسم المورد</span></td>
        <td class="text-right">
          <span> {{ invoice.id }}/ مر</span>
        </td>
        <td class="text-right"><span> رقم الفاتورة</span></td>
      </tr>
      <tr>
        <td class="text-right"><span>1010152299</span></td>
        <td class="text-right"><span> رقم السجل التجارى</span></td>
        <td class="text-right">
          <span> {{ invoice.created_at | moment("d, MMMM YYYY") }}</span>
        </td>
        <td class="text-right"><span>تاريخ الفاتورة</span></td>
      </tr>
      <tr>
        <td class="text-right">
          <span><u>300047805100003</u></span>
        </td>
        <td class="text-right"><span> الرقم الضريبى </span></td>
        <td class="text-right">
          <span>
            من {{ invoice.invoice_end }} الى {{ invoice.invoice_start }}</span
          >
        </td>
        <td class="text-right"><span>مدة الفاتورة</span></td>
      </tr>
      <tr>
        <td class="text-right"><span> 4173</span></td>
        <td class="text-right"><span>صندوق بريد</span></td>
      </tr>
      <tr>
        <td class="text-right"><span>11491</span></td>
        <td class="text-right"><span>الرمز البريدى</span></td>
        <td colspan="2" class="text-center"><span>نــوع الفاتورة</span></td>
      </tr>
      <tr>
        <td></td>
        <td class="text-right"><span>الملز , الرياض</span></td>
        <td class="text-right" colspan="2"><span>اخــري</span></td>
      </tr>
      <tr>
        <td class="text-right"><span>الى</span></td>
      </tr>
      <tr>
        <td class="text-right">
          <span>شركة مرافق الكهرباء والمياء بالجبيل وينبع</span>
        </td>
        <td class="text-right"><span>اســم الشـركة</span></td>
      </tr>
      <tr>
        <td class="text-right"><span>30144</span></td>
        <td class="text-right"><span>صندوق بريد</span></td>
      </tr>
      <tr>
        <td class="text-right" colspan="2">
          <span>41912 ينبع الصنا عية - الرمز البريدى </span>
        </td>
      </tr>
      <tr>
        <td class="text-right">
          <span><u>300000419900003</u></span>
        </td>
        <td class="text-right"><span>الرقم الضريبى</span></td>
      </tr>
    </table>
    <div class="text-right">
      <span> {{ invoice.contract_no }} :رقـــم العــقــد</span>
    </div>
    <table class="table table-bordered line-height">
      <tr>
        <th class="text-center"><span>األجمالي</span></th>
        <th class="text-center"><span>الساعات المنجزة</span></th>
        <th class="text-center"><span>سعر الساعــة</span></th>
        <th class="text-center"><span>البـيـان</span></th>
        <th class="text-center"><span>م</span></th>
      </tr>
      <tr v-for="(value, index) in items" :key="index">
        <td>
          <span>{{ value.amount }}</span>
        </td>
        <td>
          <span>{{ value.quantity }}</span>
        </td>
        <td>
          <span>{{ value.price }}</span>
        </td>
        <td class="text-right">
          <span>{{ value.item }}</span>
        </td>
        <td>1</td>
      </tr>

      <tr>
        <td>
          <span>{{ invoice_total_amount.toFixed(2) }}</span>
        </td>
        <td colspan="3" class="text-right">
          <span>االجمــالي قبل الضريبه باللاير السعودي</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>{{ invoice_vat_amount.toFixed(2) }}</span>
        </td>
        <td colspan="3" class="text-right">
          <span>نسبة ضريبة القيمة المضافة %15 باللاير السعودي</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>{{ invoice_gross_amount.toFixed(2) }}</span>
        </td>
        <td colspan="3" class="text-right">
          <span>االجمــــــالى باللاير السعودي بعد الضريبة</span>
        </td>
      </tr>
    </table>
    <div class="text-right">
      <span
        >القيمة بالحروف : فقط تسعة وثمانون الف وواحد وستون لاير وخمسة وسبعون
        هلله الغير .</span
      >
    </div>
    <div class="text-left mt-5">
      <span class="border-top border-dark">مـــديــر النقد</span>
      <div>
        <span>أ / يعقوب فضل اللّه</span>
      </div>
    </div>
    <div class="row">
    <img src="./ifooter.png" alt="header" width="100%">
  </div>
  </div>
</template>

<script>
import { methods } from "@chenfengyuan/vue-qrcode";
import { Vue } from "vue-property-decorator";
import HRMService from "../../../../services/hrm-service";

export default {
  name: "SalesInvoiceYanbu",
  data: () => {
    return {
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount =  this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount);
          
          // this.items.forEach((x) => {
          //   this.invoice_total_amount += Number(x.amount);
          // });
          // this.invoice_vat_amount = this.invoice_total_amount * 0.15;
          // this.invoice_gross_amount =
          //   this.invoice_total_amount + this.invoice_vat_amount;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
span {
  font-size: 15px;
}
.line-height {
  line-height: 10px;
}
</style>

