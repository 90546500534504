var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-4",staticStyle:{"width":"800px"}},[_vm._m(0),_c('h4',{staticClass:"text-center"},[_vm._v(" فاتورة ضريبية")]),_c('table',{staticClass:"table table-bordered line-height"},[_c('tr',[_vm._m(1),_vm._m(2),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm.invoice.id)+"/ مر")])]),_vm._m(3)]),_c('tr',[_vm._m(4),_vm._m(5),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.invoice.created_at,"d, MMMM YYYY")))])]),_vm._m(6)]),_c('tr',[_vm._m(7),_vm._m(8),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" من "+_vm._s(_vm.invoice.invoice_end)+" الى "+_vm._s(_vm.invoice.invoice_start))])]),_vm._m(9)]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]),_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm.invoice.contract_no)+" :رقـــم العــقــد")])]),_c('table',{staticClass:"table table-bordered line-height"},[_vm._m(18),_vm._l((_vm.items),function(value,index){return _c('tr',{key:index},[_c('td',[_c('span',[_vm._v(_vm._s(value.amount))])]),_c('td',[_c('span',[_vm._v(_vm._s(value.quantity))])]),_c('td',[_c('span',[_vm._v(_vm._s(value.price))])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(value.item))])]),_c('td',[_vm._v("1")])])}),_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.invoice_total_amount.toFixed(2)))])]),_vm._m(19)]),_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.invoice_vat_amount.toFixed(2)))])]),_vm._m(20)]),_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.invoice_gross_amount.toFixed(2)))])]),_vm._m(21)])],2),_vm._m(22),_vm._m(23),_vm._m(24)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('img',{attrs:{"src":require("./iheader.png"),"alt":"header","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v("شركة ابناء عبدالرحمن على البصيلى")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" اسم المورد")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" رقم الفاتورة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v("1010152299")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" رقم السجل التجارى")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v("تاريخ الفاتورة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_c('u',[_vm._v("300047805100003")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" الرقم الضريبى ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v("مدة الفاتورة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(" 4173")])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("صندوق بريد")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("11491")])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("الرمز البريدى")])]),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_c('span',[_vm._v("نــوع الفاتورة")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("الملز , الرياض")])]),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('span',[_vm._v("اخــري")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("الى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("شركة مرافق الكهرباء والمياء بالجبيل وينبع")])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("اســم الشـركة")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("30144")])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("صندوق بريد")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('span',[_vm._v("41912 ينبع الصنا عية - الرمز البريدى ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right"},[_c('span',[_c('u',[_vm._v("300000419900003")])])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v("الرقم الضريبى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("األجمالي")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("الساعات المنجزة")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("سعر الساعــة")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("البـيـان")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("م")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('span',[_vm._v("االجمــالي قبل الضريبه باللاير السعودي")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('span',[_vm._v("نسبة ضريبة القيمة المضافة %15 باللاير السعودي")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('span',[_vm._v("االجمــــــالى باللاير السعودي بعد الضريبة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-right"},[_c('span',[_vm._v("القيمة بالحروف : فقط تسعة وثمانون الف وواحد وستون لاير وخمسة وسبعون هلله الغير .")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mt-5"},[_c('span',{staticClass:"border-top border-dark"},[_vm._v("مـــديــر النقد")]),_c('div',[_c('span',[_vm._v("أ / يعقوب فضل اللّه")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('img',{attrs:{"src":require("./ifooter.png"),"alt":"header","width":"100%"}})])
}]

export { render, staticRenderFns }